<template>
  <teleport :is="location ? 'teleport' : 'clean-wrapper'" :to="location">
    <div
      v-if="showTrigger"
      v-bind="$attrs"
      :class="finalTriggerClass"
      class="floating-widget-trigger cta-block padding-m cursor-pointer flex flex-middle flex-center text-center text-3xl border-radius-round text-center"
      @click="opened = !opened">
      <icon-text v-if="triggerText" :icon="triggerIcon">{{
        triggerText ? t(triggerText) : ""
      }}</icon-text>
      <icon v-else :icon="triggerIcon"></icon>
    </div>
  </teleport>
  <drawer
    v-bind="drawerProps"
    ref="drawer"
    v-model="opened"
    :drawer-style="finalDrawerStyle">
    <slot
      name="default"
      v-bind="{
        floatingWidget: this,
        widget: this,
        drawerRef: $refs.drawer,
        isOpened: opened,
      }">
    </slot>
  </drawer>
</template>

<script>
import floatingWidgetMixin from "@/client/extensions/mixins/baseComponents/floatingWidget.js";
export default {
  mixins: [floatingWidgetMixin],
  props: {
    triggerTheme: {
      type: String,
      default: "bg",
    },
    triggerBgTheme: {
      type: String,
      default: "lead",
    },
  },
};
</script>

<style scoped lang="scss">
.floating-widget-trigger {
  position: fixed;
  z-index: var(--z-top-1);
  bottom: var(--margin-xl);
  inset-inline-end: var(--margin-xl);
  opacity: 1;
  will-change: opacity;
  transition: opacity 150ms;

  &:hover {
    opacity: 0.8;
  }
}
</style>
