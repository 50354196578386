export default {
  props: {
    modelValue: {
      type: [Boolean, undefined],
      default: undefined,
    },
    location: {
      type: [String, Boolean],
      default: "body",
    },
    showTrigger: {
      type: Boolean,
      default: true,
    },
    triggerIcon: {
      type: String,
      default: "settings",
    },
    triggerText: {
      type: [Boolean, String],
      default: false,
    },
    triggerType: {
      type: String,
      default: "button",
    },
    triggerClass: {
      type: [String, Object],
      default: {},
    },
    drawerProps: {
      type: Object,
      default: {},
    },
  },
  data: function () {
    return {
      localIsOpened: false,
      isFullWidth: false,
    };
  },
  computed: {
    opened: {
      get() {
        if (typeof this.modelValue === "undefined") {
          return this.localIsOpened;
        }
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        this.localIsOpened = value;
      },
    },
    finalTriggerClass() {
      const result = [];

      if (typeof this.triggerClass === "string") {
        result.push(this.triggerClass);
      }

      if (this.triggerClass && typeof this.triggerClass === "object") {
        result.push(Object.values(this.triggerClass).join(" "));
      }

      if (this.opened) {
        result.push("active");
      }

      result.push(`bg-${this.triggerBgTheme}`);
      result.push(`c-${this.triggerTheme}`);
      return result;
    },
  },
  watch: {
    opened(newVal) {
      if (!newVal) {
        this.isFullWidth = false;
      }
    },
  },
}
